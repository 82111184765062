jQuery(document).ready(function () {
    jQuery('.bxslider').bxSlider({
		pager:  (jQuery('.bxslider').children().length < 2) ? false : true,
		controls: false,
		auto: (jQuery('.bxslider').children().length < 2) ? false : true,
		touchEnabled:(jQuery('.bxslider').children().length < 2) ? false : true,
        useCSS: false
    }); 
    
    jQuery(".mobile-menu").on("click touchstart", function (event) {    
        event.preventDefault();
		event.stopPropagation();
		jQuery("html").toggleClass("menu-open");
    });
    
   jQuery(document).on('click touchstart', function () {
        jQuery('html').removeClass('menu-open');
    });
    
     jQuery(".site-header .header-mobile").on("click touchstart", function (event) {
		event.stopPropagation();		
    }); 
    
    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){
        if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0){
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });
});